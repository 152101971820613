import { Coupon } from 'common/types/CouponType'
import { PricePlan } from 'common/types/OfferInterface'
import {
  ProductInterface,
  ProductVariantInterface,
} from 'common/types/entities/ProductInterface'
import { OfferPricing } from 'common/types/pricingTypes'
import { CalculatePriceData } from 'common/types/shippingType'
import { fetchOfferPricing } from 'publisher/pages/offer-page/utils/fetchOfferPricing'

export interface GetOfferPricing {
  product: ProductInterface | null
  productBump?: ProductInterface | null
  pricePlans: PricePlan[]
  bumpPlan?: PricePlan

  offerId: number
  productQuantity: number
  activeProductVariant: ProductVariantInterface | null
  country?: string
  coupon?: Coupon
  taxNumber?: string

  abortSignal?: AbortSignal
}

export const calculateOfferPricing = async ({
  product,
  productBump,
  pricePlans,
  bumpPlan,

  offerId,
  productQuantity,
  activeProductVariant,
  country,
  coupon,
  taxNumber,

  abortSignal,
}: GetOfferPricing): Promise<OfferPricing[]> => {
  if (!offerId || (!product?.id && !pricePlans.length)) return []

  const offers: CalculatePriceData[] = pricePlans.map(pricePlan => ({
    quantity: 1,
    offer: offerId,
    coupon: coupon?.id ?? null,
    pricePlan: pricePlan.id,
    country: country ?? null,
    taxNumber,
  }))

  // may or may not be included in general price plans list
  if (bumpPlan && !pricePlans.some(pp => pp.id === bumpPlan.id)) {
    offers.push({
      quantity: 1,
      offer: offerId,
      pricePlan: bumpPlan.id,
      // bumps are a special case with coupon not being applicable
      coupon: null,
      country: country ?? null,
      taxNumber,
    })
  }

  if (product?.id) {
    offers.push({
      product: product?.id,
      productVariant: activeProductVariant?.id,
      quantity: productQuantity,
      offer: offerId,
      coupon: coupon?.id ?? null,
      country: country ?? null,
      taxNumber,
    })
  }

  if (productBump?.id) {
    offers.push({
      product: productBump?.id,
      quantity: 1,
      offer: offerId,
      // bumps are a special case with coupon not being applicable
      coupon: null,
      country: country ?? null,
      taxNumber,
    })
  }

  const newOfferPricings = await fetchOfferPricing(offers, abortSignal)

  return newOfferPricings
}
